var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('nav-component',{attrs:{"drawer":true,"title":"Dashboard Users"}}),_c('v-main',{staticClass:"pt-2 pt-md-0"},[_c('user-component',{ref:"userComponent",attrs:{"user":this.activeItem},on:{"reload":function($event){return _vm.loadData()}}}),_c('alert-component',{ref:"disableAlert"}),_c('v-data-table',{attrs:{"headers":_vm.headers(),"items":_vm.users},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":" blue-grey lighten-5"}},[_c('v-toolbar-title',[_vm._v("Users list")]),_c('v-switch',{attrs:{"label":"Display disabled"},model:{value:(_vm.shouldDisplayDisabled),callback:function ($$v) {_vm.shouldDisplayDisabled=$$v},expression:"shouldDisplayDisabled"}}),_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.hasRole(['ROLE_ADMIN']),"color":"secondary"},on:{"click":_vm.openDialog}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" New Item ")],1)],1)]},proxy:true},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_c('timeago',{attrs:{"datetime":item.createdAt}})]}},{key:"item.role",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.roles[item.role])+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_c('timeago',{attrs:{"datetime":item.updatedAt}})]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(_vm.hasRole(['ROLE_ADMIN']))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v("mdi-pencil ")]):_vm._e(),(_vm.hasRole(['ROLE_ADMIN']))?_c('v-icon',{staticClass:"mr-2",class:{'color--red': item.delete},attrs:{"small":""},on:{"click":function($event){return _vm.disableItem(item)}}},[_vm._v(" mdi-block-helper ")]):_vm._e(),(!_vm.hasRole(['ROLE_ADMIN']) && item.delete)?_c('span',{attrs:{"type":"text"}},[_vm._v("Disabled")]):_vm._e()]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',[_c('v-text-field',{attrs:{"type":"text","label":"unsername"},model:{value:(_vm.usernameContains),callback:function ($$v) {_vm.usernameContains=$$v},expression:"usernameContains"}})],1),_c('v-select',{attrs:{"type":"text","label":"Role","items":Object.keys(_vm.roles).map(function (r) {return {text: _vm.roles[r], value: r}})},model:{value:(_vm.roleContains),callback:function ($$v) {_vm.roleContains=$$v},expression:"roleContains"}}),_c('td'),_c('td'),_c('td')],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }