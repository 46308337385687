const ALPHABET = ['K', 'M', 'B', 'T'];
const THRESHOLD = 1e3;

export default {
    methods: {
        humanize2(n, digits) {
            if (!n) return;

            let idx = 0,
                x = n;
            while (x >= THRESHOLD && ++idx <= ALPHABET.length)
                x /= THRESHOLD;

            if (digits && x % 1 !== 0)
                x = Number.parseFloat(x).toFixed(digits);

            return {
                human: x,
                symbol: idx === 0 ? '' : ALPHABET[idx - 1],
                formatted: n.toLocaleString()
            }
        }
    }
}
