<template>
  <v-card flat>
    <v-row>
      <v-card-title>Transactions Report</v-card-title>
      <v-card-actions>
        <v-btn v-if="results && results.length" block color="secondary" v-text="'EXPORT'" @click="transactionsReportCsv"/>
      </v-card-actions>
    </v-row>
    <v-card-subtitle v-if="results">
      Total API transactions in period: {{ totals.requests }} requests, {{ totals.initiated }} initiated and
      {{ totals.completed }} completed.
    </v-card-subtitle>
    <v-card-text>
      <v-data-table hide-default-footer disable-pagination
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="[
                      { text: 'Date', value: 'date' },
                      { text: 'Validation Requests', value: 'validation.requests' },
                      { text: 'Validation Initiated', value: 'validation.initiated' },
                      { text: 'Validation Responses', value: 'validation.completed' },
                      { text: 'Approval Requests', value: 'approval.requests' },
                      { text: 'Approval Initiated', value: 'approval.initiated' },
                      { text: 'Approval Responses', value: 'approval.completed' },
                    ]"
                    :items="results">
        <template v-slot:item.date="{ item }">
          <span v-text="$date(item.date, 'P')"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import utils from "@/mixins/utils";

export default {
  name: "TransactionsReport",
  mixins: [utils],
  props: {},
  data() {
    return {
      loading: false,
      results: [],
      totals: {}
    }
  },
  methods: {
    async loadData(params) {
      this.loading = true;

      this.results = (await this.httpGet('api/report/mno/transactions?' + new URLSearchParams(params)))
          .data.map(item => ({
            date: new Date(item[0]),
            ...item[1]
          }))

      this.totals = {
        requests: this.results.reduce((sum, x) => (sum + x.validation.requests + x.approval.requests), 0),
        initiated: this.results.reduce((sum, x) => (sum + x.validation.initiated + x.approval.initiated), 0),
        completed: this.results.reduce((sum, x) => (sum + x.validation.completed + x.approval.completed), 0),
      }

      this.loading = false;
    },
    transactionsReportCsv() {
      this.dateFormat = this.time === 'HOURLY' ? 'yyyy-MM-dd HH:00' : 'yyyy-MM-dd'
      let blobHeaders = 'Date, Validate Requests, Validate Initiated, Validate Responses, Approve Requests, Approve Initiated, Approve Responses\r\n'
      this.csvGeneric('TransactionsRepository', blobHeaders,(item) => {
        return this.$date(new Date(item.date), this.dateFormat) + ',' +
            item.validation.requests + ',' +
            item.validation.initiated + ',' +
            item.validation.completed + ',' +
            item.approval.requests + ',' +
            item.approval.initiated + ',' +
            item.approval.completed + '\r\n';
      });
    },
  }
}
</script>
