<script>

import utils from "@/mixins/utils";
import authService from '@/authService';

export default {
  async mounted() {
    const result = await this.httpGet('api/auth/login3');
    if (result) {
      authService.login();
      localStorage.user = JSON.stringify(result.data);
      this.$router.replace({path: '/'});
    }
  },
  mixins: [utils]
}
</script>
