<template>
  <v-card flat>
    <v-card-title>Users Report</v-card-title>
    <v-card-subtitle>Total in period: N/A Unique MSISDNs in API</v-card-subtitle>
    <v-card-text>Coming soon... Stay tuned</v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "UsersReport",
}
</script>
<style scoped>

</style>