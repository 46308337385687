<template>
  <v-card flat>
    <v-card-title>RequestID Report</v-card-title>
    <v-card-subtitle>Activity log of a given request ID</v-card-subtitle>
    <v-card-text>Coming soon... Stay tuned</v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "RequestIDReport",
}
</script>
<style scoped>

</style>