<template>
  <v-row>

    <v-col cols="12" md="4">
      <v-card>
        <v-card-title>Active Users</v-card-title>
        <v-card-subtitle>Unique MSISDNs with any API transaction</v-card-subtitle>
        <v-card-text v-if="humanTotals && humanTotals.uniqueActiveUsers" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="text-h1">{{ humanTotals.uniqueActiveUsers.human }}</span>
                <span class="text-h3">{{ humanTotals.uniqueActiveUsers.symbol }}</span>
              </div>
            </template>
            <span> {{ humanTotals.uniqueActiveUsers.formatted }} </span>
          </v-tooltip>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span class="text-h1">N/A</span>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card>
        <v-card-title>Transactions</v-card-title>
        <v-card-subtitle>Successfully completed API transactions</v-card-subtitle>
        <v-card-text v-if="humanTotals && humanTotals.transactions" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="text-h1">{{ humanTotals.transactions.human }}</span>
                <span class="text-h3">{{ humanTotals.transactions.symbol }}</span>
              </div>
            </template>
            <span> {{ humanTotals.transactions.formatted }} </span>
          </v-tooltip>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span class="text-h1">N/A</span>
        </v-card-text>
      </v-card>
    </v-col>

    <v-col cols="12" md="4">
      <v-card>
        <v-card-title>SDKs</v-card-title>
        <v-card-subtitle>All time number of Android SDK installations</v-card-subtitle>
        <v-card-text v-if="humanTotals && humanTotals.sdkInstallations" class="text-center">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <span class="text-h1">{{ humanTotals.sdkInstallations.human }}</span>
                <span class="text-h3">{{ humanTotals.sdkInstallations.symbol }}</span>
              </div>
            </template>
            <span> {{ humanTotals.sdkInstallations.formatted }} </span>
          </v-tooltip>
        </v-card-text>
        <v-card-text v-else class="text-center">
          <span class="text-h1">N/A</span>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import utils from "@/mixins/utils";
import humanize from "@/mixins/humanize";

export default {
  name: 'Totals',
  mixins: [utils, humanize],
  async mounted() {
    // await this.loadData();
  },
  props: {
    customerId: {
      type: String,
      default: null
    }
  },
  watch: {
    customerId() {
      this.loadData();
    }
  },
  data() {
    return {
      totals: null,
      humanTotals: null,
    }
  },
  methods: {
    async loadData() {
      const params = {}
      if (this.customerId) {
        params.customerId = this.customerId;
      }
      this.totals = (await this.httpGet('api/report/mno/totals?' + new URLSearchParams(params))).data;
      this.humanTotals = Object.fromEntries(
          Object.entries(this.totals).map(([k, v]) => [k, humanize.methods.humanize2(v, 1)]))
    },
  }
}

</script>
