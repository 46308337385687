<template>
  <v-dialog v-model="dialog" width="unset">
    <v-card style="padding: 20px;">
      <v-card-title v-if="title" v-text="title"/>
      <v-card-text v-if="text" v-text="text"/>
      <hr v-if="buttons && buttons.length > 0">
      <v-card-actions>
        <v-btn v-for="(button, i) of buttons" :key="i" v-text="button.text" @click="() => {
            if (button.callback !== undefined) {
              button.callback();
            }
            dialog = false;
          }"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'AlertComponent',
  props: {
  },
  data() {
    return {
      dialog: false,
      title: null,
      text: null,
      buttons: null,
    }
  },
  methods: {
    show (title, text, buttons) {
      this.title = title;
      this.text = text;
      this.buttons = buttons;
      this.dialog = true;
    }
  }
}
</script>
<style scoped>

</style>
