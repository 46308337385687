var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-row',[_c('v-card-title',[_vm._v("Transactions Report")]),_c('v-card-actions',[(_vm.results && _vm.results.length)?_c('v-btn',{attrs:{"block":"","color":"secondary"},domProps:{"textContent":_vm._s('EXPORT')},on:{"click":_vm.transactionsReportCsv}}):_vm._e()],1)],1),(_vm.results)?_c('v-card-subtitle',[_vm._v(" Total API transactions in period: "+_vm._s(_vm.totals.requests)+" requests, "+_vm._s(_vm.totals.initiated)+" initiated and "+_vm._s(_vm.totals.completed)+" completed. ")]):_vm._e(),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","disable-pagination":"","loading":_vm.loading,"loading-text":"Loading... Please wait","headers":[
                    { text: 'Date', value: 'date' },
                    { text: 'Validation Requests', value: 'validation.requests' },
                    { text: 'Validation Initiated', value: 'validation.initiated' },
                    { text: 'Validation Responses', value: 'validation.completed' },
                    { text: 'Approval Requests', value: 'approval.requests' },
                    { text: 'Approval Initiated', value: 'approval.initiated' },
                    { text: 'Approval Responses', value: 'approval.completed' } ],"items":_vm.results},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                  var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.$date(item.date, 'P'))}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }