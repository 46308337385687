<template>
  <v-app>
    <nav-component :drawer="false" title="Login"/>
    <v-main>
      <div style="alignment: center;">
        <v-card style="max-width: 600px; margin: 150px auto auto auto; padding: 50px;">
          <h1>Login</h1>
          <div style="display: flex; flex-direction: row; justify-content: center;">
            <span style="width: 80px; margin-top: 21px; margin-right: 10px;">Token:</span>
            <v-text-field type="number" v-model="token"/>
          </div>
          <p>Contact your admin to reset 2FA for your user.</p>
          <v-btn color="secondary" @click="login">login</v-btn>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";
import authService from '@/authService';

export default {
  mixins: [utils],
  data() {
    return {
      token: null
    }
  },
  components: {
    NavComponent
  },
  methods: {
    async login() {
      if (!this.token || this.token.length === 0) {
        alert('You have to enter the token')
        return
      }

      const result = await this.httpGet('api/auth/login2', {'token': this.token});
      if (result) {
        if (!result.data.success) {
          alert('Wrong token');
          return;
        }

        authService.login();
        localStorage.user = JSON.stringify(result.data);
        this.$router.replace({path: '/'});
      }
    }
  }
}
</script>
