<template>
  <router-view></router-view>
</template>

<script>
  export default {
    data() {
      return {}
    },
    components: {
    },
    methods: {
    }
  }
</script>
<style scoped>
  .cardRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 10px;
  }

  .card {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 20px;
    padding: 20px;
  }

  .center {
    text-align: center;
  }

  .flexRow {
    display: flex;
    flex-direction: row;
  }

  .spacer {
    flex-grow: 1;
  }
</style>
