<template>
  <v-card flat>
    <v-card-title>SDK Installations Report</v-card-title>
    <v-card-subtitle v-if="results && results.length">All time number of Android SDK installations</v-card-subtitle>
    <v-card-text>Coming soon... Stay tuned</v-card-text>
  </v-card>
</template>

<script>

export default {
  name: "SDKReport",
}
</script>
<style scoped>

</style>