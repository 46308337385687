<template>
  <v-app>
    <nav-component :drawer="true" title="Overview"/>
    <v-main>
      <template>
        <v-container fluid>
          <v-row align="center" justify="center">
            <v-col cols="12" sm="6">
              <v-card>
                <v-img max-height="150" max-width="600" :src="require('@/assets/images/logo.png')"></v-img>
                <v-card-title class="text-h6">
                  Welcome to the Unibeam dashboard
                </v-card-title>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </v-main>
  </v-app>
</template>


<script>
import utils from "@/mixins/utils";
import NavComponent from "@/components/NavComponent";

export default {
  mixins: [utils],
  components: {
    NavComponent
  },
}
</script>

<style scoped>
</style>
